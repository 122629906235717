<template>
  <main class="main-layout-wrap">
    <div class="content">
      <Feature visible="VUE_APP_NSA_1620">
        <PlanComponent />
      </Feature>
      <Feature visible="!VUE_APP_NSA_1620">
        <PlanComponentOld />
      </Feature>
    </div>
  </main>
</template>

<script>
import PlanComponent from '../components/Plan';
import PlanComponentOld from '../components/PlanOld';
import Feature from '@/components/app/Feature';

export default {
  name: 'Plan',
  computed: {
  },
  components: {
    PlanComponent,
    PlanComponentOld,
    Feature
  }
};
</script>
